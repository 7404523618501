:root {
    --clr-1: #ffffff;
    --clr-2: #d3e4e2;
    --clr-3: #373a36;
    --clr-4: #008bce;
    --clr-5: #f2a900;
    --clr-6: #b3c50e;
    --clr-7: #d9d9d6;

}

/*===================================================================*/
html {
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow-x: hidden;
}

/*===================================================================*/
/*===================================================================*/
.top-part {
    background-color: var(--clr-4);
    color: var(--clr-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    height: 47vw;
    position: relative !important;
}

.slider-text {
    width: 100%;
    height: 30vw;
}

.title {
    text-align: center;
    font-size: 8.5vw;
    display: flex;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0vw;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.title-text {
    padding-left: 3%;
}

.touch-icon {
    fill: var(--clr-1);
    color: var(--clr-1);
    padding: 0% 0% 2% 0%;
    width: 5vw;
    height: fit-content;
}

.subtitle {
    text-align: center;
    font-size: 2.21vw;
    font-weight: light;
    width: 100%;
    line-height: 3.15vw;
    margin-top: 0%;
    padding-top: 1%;
}

.slider-image {
    width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.carousel .carousel-indicators button {
    background-color: transparent;
    border: 1px solid var(--clr-1);
    border-radius: 50%;
    width: 0.7vw;
    height: 0.7vw;
    margin-bottom: 2.9%;
    margin-left: 0.9%;
    margin-right: 0.9%;
}

.carousel .carousel-indicators button.active {
    background-color: var(--clr-1);
}

.carousel-control-next,
.carousel-control-prev {
    padding: 0%;
    margin: 0% -4%;
    opacity: 1 !important;
    color: var(--clr-1);
    position: absolute !important;
    z-index: 1 !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-size: 100% 100%;
    height: 5vw !important;
    width: 5vw !important;
}

/*===================================================================*/
.middle-part {
    background-color: var(--clr-2);
    color: var(--clr-3);
    font-size: calc(10px + 2vmin);
    padding: 2.5% 3%;
    position: relative !important;
    z-index: 2 !important;
}

.adverts-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    width: 100%;
    font-size: calc(0px + 1.1vw);
    padding: 0%;
}

.adverts-title {
    display: flex;
    align-items: center;
    width: 8%;
    color: var(--clr-1);
    background-color: var(--clr-4);
    font-weight: bold;
    align-items: middle;
    justify-content: center;
}

.adverts-content {
    float: right;
    width: 92%;
    color: var(--clr-3);
    background-color: var(--clr-7);
    padding: 0.7%;
    padding-left: 1.5%;
    display: flex;
    align-items: center;
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}

.adverts-content a {
    text-decoration: none;
    color: var(--clr-3);
}

.adverts-content a:hover {
    text-decoration: none;
    color: var(--clr-4);
}

.text-marquee {
    height: 1rem !important;
    padding: 1%;
}

.marquee-text {
    margin: 0%;
}

.buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    grid-gap: 4%;
    padding-top: 2.5%;
    height: 8.8vw;
}

.menu-button {
    background-color: var(--clr-1);
    color: var(--clr-3);
    fill: var(--clr-3);
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    border-top: 10px solid var(--clr-3);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-icon {
    height: 100%;
    padding: 5% 7% 5% 0%;
    width: max-content;

}

.cetec-icon {
    width: 60%;
    height: fit-content;
    padding: 5%;
}

.button-text {

    position: relative;
    text-align: left;
    font-size: calc(1.6vw + 2px);
    display: flex;
    align-items: center;
    line-height: 120%;
    width: min-content;

}

.menu-button:hover {
    color: var(--clr-1);
    fill: var(--clr-1);
    font-weight: bolder;
}

.book-button:hover {
    background-color: var(--clr-5);
    border-top: 10px solid var(--clr-5);
}

.hat-button:hover {
    background-color: var(--clr-6);
    border-top: 10px solid var(--clr-6);
}

.teacher-button:hover {
    background-color: var(--clr-4);
    border-top: 10px solid var(--clr-4);
}

.cetec-button:hover {
    background-color: var(--clr-4);
    border-top: 10px solid var(--clr-4);
}

/*===================================================================*/
.bottom-part {
    background-color: var(--clr-3);
    color: var(--clr-1);
    font-size: calc(10px + 2vmin);
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.footer-text {
    font-size: calc(4px + 0.7vw);
    font-weight: lighter;
    line-height: 1vw;
    vertical-align: middle;
    width: 50%;
    padding: 3%;
    position: relative !important;
    z-index: 3 !important;
}

.footer-text-bold {
    font-weight: bold;
}

.footer-image {

    padding: 0%;
    padding-right: 3%;
    max-width: 30%;
}

/*===================================================================*/
/*===================================================================*/
/*===================================================================*/
@media (max-width: 768px) and (orientation: portrait) {
    .top-part {
        height: 109vw;
    }

    .slider-text {
        height: 100%;
    }

    .title {
        font-size: 14vw;
        display: flex;
        flex-direction: column;
        padding-bottom: 2%;
    }

    .subtitle {

        font-size: 4vw;
        line-height: 6vw;
        margin-bottom: 20%;
        padding-top: 2%;
        margin-left: 0%;
        margin-right: 0%;
        padding-left: 0%;
        padding-right: 0%;
        text-align: center;
    }

    .touch-icon {
        width: 20%;
        padding-bottom: 0%;
    }

    .title-text {
        padding: 0%;
        margin: 0%;
    }

    .carousel .carousel-indicators button {
        width: 1.8vw;
        height: 1.8vw;
        border-width: 1px;
        margin-bottom: 6%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .slider-image {
        object-fit: cover;
        height: 109vw;
    }

    .carousel-control-next,
    .carousel-control-prev {
        margin: 0vw 1vw;
        height: 100%;
        /*115%*/
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        height: 8vw !important;
        width: 8vw !important;
        margin-top: 100%;
    }

    /*===================================================================*/
    .middle-part {
        padding: 0%;
    }

    .adverts-container {
        font-size: calc(8px + 1vw);
        padding: 3%;
    }

    .adverts-title {
        width: 20%;
        padding: 1.5%;
        font-size: 3vw;
    }

    .adverts-content {
        width: 80%;
        padding: 1.5%;
        padding-left: 3%;
    }

    .buttons-container {
        grid-gap: 0.4%;
        padding: 0%;
        margin: 0%;
        height: calc(38vw);
        background-color: var(--clr-3);
    }

    .menu-button {
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .button-icon {
        width: 70%;
        height: 64%;
        padding: 10%;
        margin: 0%;
        padding-top: 25%;
        overflow: visible;
    }

    .button-text {
        width: 80%;
        font-size: calc(2vw + 5px);
        align-items: baseline;
        justify-content: center;
        text-align: center;
        height: 36%;
        margin: auto;
    }

    .cetec-icon {
        width: 100%;
        padding: 10%;
        margin: 0%;
        height: 100%;
    }

    /*===================================================================*/
    .bottom-part {
        width: 100%;
        margin: 0%;
        padding: 0%;
        display: flex;
    }

    .footer-text {
        font-size: calc(5px + 0.8vw);
        line-height: 0.8vw;
        text-align: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding: 5% 3% 3% 3%;
        width: 100%;
    }

    .footer-image {
        position: fixed;
        visibility: hidden;
        display: none;
    }
}

@media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 64/27) {

    .top-part {
        height: 67vh;
    }

    .middle-part {
        height: 33vh;
    }

    .adverts-container {
        padding-bottom: 2.5%;
    }

    .buttons-container {
        padding-top: 0%;
        height: 60%;
    }

    .button-icon {
        height: 11vh;
    }

    .slider-text {
        height: 33vw;
    }

    .carousel .carousel-indicators button {
        margin-top: -10vw;
    }
}

@media (min-aspect-ratio: 27/64) and (max-aspect-ratio: 1/1) and (max-width: 768px) {

    .top-part {
        height: 60vh;
    }

    .slider-image {
        object-fit: cover;
        height: 60vh;
    }

    .slider-text {
        display: table-cell;
        vertical-align: bottom;
    }

    .title {
        font-size: calc(6vh + 4vw);
        padding-bottom: 0%;
    }

    .subtitle {
        font-size: calc(2vh + 1vw);
        padding-top: 0%;
        padding-bottom: 0%;
        margin-bottom: calc(12vh - 55px);
    }

    .touch-icon {
        width: calc(6vh + 4vw);
    }

    .carousel .carousel-indicators button {
        margin-bottom: 2%;
    }

    .middle-part {
        height: 30vh;
    }

    .adverts-container {
        padding: 2vh;
        height: 8vh;
    }

    .buttons-container {
        height: 22vh;
    }

    .button-icon {
        width: 80%;
    }

    .teacher-icon {
        padding: 11%;
    }

    .slider-text {
        height: 80vw;
    }

    .bottom-part {
        height: 10vh;
        display: flex;
        align-items: center;
    }

}